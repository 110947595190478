import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const shortMonthFormat = "DD MMM YYYY, HH:mm";
const longMonthFormat = "MMMM DD YYYY, HH:mm";

export const formatBuildDateTime = (
  inputDate: string,
  format: "long" | "short"
): string => {
  const date = dayjs(inputDate);
  if (!date.isValid()) {
    return "Invalid date";
  }

  const today = dayjs();
  const diff = today.diff(date, "day");

  if (format === "long") {
    return diff === 0 ? date.fromNow() : date.format(longMonthFormat);
  } else if (format === "short") {
    return diff === 0 ? date.fromNow() : date.format(shortMonthFormat);
  }
};

export const formatFileSize = (fileSize: number): string =>
  fileSize && !isNaN(fileSize) ? `${fileSize} MB` : "Size unavailable";

export default {
  formatFileSize,
  formatBuildDateTime,
};
