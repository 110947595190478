import { Component, h, JSX } from "preact";

interface Rule {
  errorMessage: string;
  rule: (value: string) => boolean;
}

type FormField = {
  valid: boolean;
  errors: string[];
  touched: boolean;
};

interface State {
  [name: string]: FormField;
}

interface Props {
  getErrorMessage?: (string) => void;
  className?: string;
  children: (validation: State) => JSX.Element;
  onSubmit?: (event?: Event, isValid?: boolean, state?: State) => void;
  onInput?: (event: Event) => void;
  rules: { [name: string]: Rule[] };
}

export default class Form extends Component<Props, State> {
  public FIELD_VALIDATION_INITIAL_VALUE = {
    valid: false,
    errors: [],
    touched: false,
  };

  public state = {};

  public constructor(props: Props) {
    super(props);
    this.setupComponent();
  }

  public setupComponent = (): void => {
    const { rules } = this.props;

    Object.keys(rules).map((key) => {
      this.setState({
        ...this.state,
        [key]: this.FIELD_VALIDATION_INITIAL_VALUE,
      });
    });
  };

  public validate = (name: string, value: string): void => {
    const rules = this.props.rules[name];

    if (typeof rules === "undefined") {
      return;
    }

    let valid = true;
    let errors: string[] = [];

    rules.forEach(({ errorMessage, rule }) => {
      if (rule(value)) {
        return;
      }
      valid = false;
      errors = [...errors, errorMessage];
    });

    const state: State = {
      [name]: { valid, errors, touched: true },
    };
    this.setState(state);
    if (this.props.getErrorMessage) {
      const field = this.state[name] as FormField;
      const error = field.touched && !field.valid && field.errors.toString();
      this.props.getErrorMessage(error);
    }
  };

  public isValid = (): boolean => {
    const keys = Object.keys(this.state);

    let i = keys.length;
    while (i--) {
      const field = this.state[keys[i]] as FormField;
      if (!field.valid) {
        return false;
      }
    }

    return true;
  };

  public handleInput = (event: Event): void => {
    this.validateInput(event);
    if (typeof this.props.onInput === "function") {
      this.props.onInput(event);
    }
  };

  public validateInput = (event: Event): void => {
    const target = event.target as HTMLInputElement;
    const { name, value } = target;

    this.validate(name, value);
  };

  public handleSubmit = (event: Event): void => {
    if (typeof this.props.onSubmit === "function") {
      this.props.onSubmit(event, this.isValid(), this.state);
    }
  };

  public getValidation = (): Record<string, unknown> => ({
    _isValid: this.isValid(),
    ...this.state,
  });

  public render(): JSX.Element {
    return (
      <form
        {...this.props}
        onInput={this.handleInput}
        onSubmit={this.handleSubmit}
      >
        {/* @ts-ignore */}
        {this.props.children(this.getValidation())}
      </form>
    );
  }
}

export const getErrorMessage = (state: State, name: string): string =>
  state[name] &&
  state[name].touched &&
  !state[name].valid &&
  state[name].errors.toString();
