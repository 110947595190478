import { FunctionalComponent, h, JSX } from "preact";
import Header from "../../components/header";
import Text from "../../components/text";
import ToggleSwitch from "../../components/toggleSwitch";

interface Props {
  isToggled: boolean;
  handleThemeChange: () => void;
  leftIcon: JSX.Element;
}

const ThemeScreen: FunctionalComponent<Props> = ({
  isToggled,
  handleThemeChange,
  leftIcon,
}) => {
  return (
    <div
      style={{
        paddingTop: 62,
      }}
    >
      <Header unfixed leftIcon={leftIcon} backgroundColor={"transparent"}>
        <Text bold={"black"} content={"settings.theme.change_theme"} />
      </Header>
      <div
        style={{
          padding: "0 var(--project-item-gutter)",
        }}
      >
        <Text
          content="settings.theme.title"
          left
          bold="bold"
          color="light-gray"
          size="slarge"
          className="mt-5 mb-4"
        />
        <div className="second-layer wide-button">
          <Text
            left
            bold="bold"
            color={"dark"}
            opacity="high"
            content="settings.theme.dark_mode"
          />
          <ToggleSwitch onToggle={handleThemeChange} isToggled={isToggled} />
        </div>
      </div>
    </div>
  );
};

export default ThemeScreen;
