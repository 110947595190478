import safeGet from "just-safe-get";
import { Component, createRef, Fragment, h, RefObject } from "preact";
import analytics from "../../utils/analytics";
import Async, { AsyncCallbackParams } from "../../components/async";
import BuildItem from "../../components/builditem";
import Button, { IconButton } from "../../components/button";
import Content from "../../components/content";
import Head from "../../components/head";
import Header, { DEFAULT_HEADER_BG_COLOR } from "../../components/header";
import Icon from "../../components/icon";
import LatestBuild from "../../components/latestbuild";
import Loading from "../../components/loading";
import MessageView from "../../components/messageView";
import Modal from "../../components/modal";
import Dialog from "../../components/modal/dialog";
import ModalMessage from "../../components/modal/message";
import Paper from "../../components/paper";
import Text from "../../components/text";
import { ProjectDetailResponse } from "../../types/responses";
import {
  getMobileOS,
  goBack,
  iOSSafari,
  isRunningInStandaloneMode,
  jwtData,
} from "../../utils/helpers";
import store from "../../utils/store";
import { checkArray, getUuifFromUrl } from "../../utils/helpers";
import { fetchBuild, getProject } from "../../utils/http";
import {
  projectURL,
  copyTextToClipboard,
  getContrastColor,
  hexToRgb,
} from "../../utils/text";
import Share from "../share/index";
import { StoreContextValue, withStore } from "../../store";
import { Build } from "../../types";
import { broadcast } from "../../components/toast";
import { withText } from "preact-i18n";

import "./index.scss";
import { route } from "preact-router";

type Response = AsyncCallbackParams<ProjectDetailResponse>;

interface Props {
  id: string;
  hash?: string;
  store: StoreContextValue;
  copiedToClipboardMessage?: string;
  defaultLogoText?: string;
  logoText?: string;
}

interface State {
  showDropDown: boolean;
  isLDAPAccount: boolean;
  id?: string;
  modalOpen: boolean;
  animateOut: boolean;
  loading: boolean;
  primaryColor?: string;
  currentHeaderBackgroundColor: string;
  incompatibleModalOpen: boolean;
}

export default withStore(
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  withText({
    copiedToClipboardMessage: "messages.copiedtoclipboard",
    defaultLogoText: "alt.default_logo",
    logoText: "alt.logo",
  })(
    class ProjectDetails extends Component<Props, State> {
      public state = {
        showDropDown: false,
        isLDAPAccount: false,
        id: undefined,
        modalOpen: false,
        animateOut: false,
        loading: false,
        primaryColor: undefined,
        currentHeaderBackgroundColor: "",
        incompatibleModalOpen: false,
      };
      private scrollTimeout;
      private toggleSharingTimeout;
      private autoScrollRef: RefObject<HTMLDivElement>;
      private projectDetailsElem: HTMLElement;
      private observer: IntersectionObserver;

      public constructor(props: Props) {
        super(props);
        this.observer = new IntersectionObserver(
          this.callbackHeaderIntersection,
          {
            root: null,
            rootMargin: "0px",
            threshold: [0.0, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
          }
        );
        this.autoScrollRef = createRef();
      }

      public componentDidMount(): void {
        const token = store.get("token");
        this.setState({
          isLDAPAccount: jwtData(token).auth_type === "LDAP",
        });
        // eslint-disable-next-line
        analytics.page();
      }

      public callbackHeaderIntersection = (
        entries: IntersectionObserverEntry[]
      ) => {
        const projectIconEntry = entries[0];

        const headerImageElement: HTMLElement = document.querySelector(
          ".header__container__project"
        );
        const projectIconElement = projectIconEntry.target as HTMLElement;
        const backgroundColor = hexToRgb(this.state.primaryColor);

        projectIconElement.style.opacity = `${projectIconEntry.intersectionRatio}`;
        headerImageElement.style.opacity = `${
          1 - projectIconEntry.intersectionRatio
        }`;

        this.setState((state) => {
          return {
            ...state,
            currentHeaderBackgroundColor: `rgba(${backgroundColor[0]},
        ${backgroundColor[1]},
        ${backgroundColor[2]},
        ${1 - projectIconEntry.intersectionRatio})`,
          };
        });
      };

      public componentWillUnmount() {
        this.observer.disconnect();
        clearTimeout(this.scrollTimeout);
        clearTimeout(this.toggleSharingTimeout);
      }

      public wireHeaderAnimation(primaryColor: string) {
        this.setState({ primaryColor });

        const projectIconElement = document.querySelector(
          ".project-details__icon"
        );

        window.scrollTo(0, 0);

        if (this.props.hash) {
          this.scrollTimeout = setTimeout(() => {
            window.scrollTo({
              behavior: "smooth",
              top: this.autoScrollRef?.current?.offsetTop - 70,
              left: 0,
            });
          }, 200);

          // set the float header opacity to 1.0 because with the autoscroll the transition does not end
          const headerBGObserver = new IntersectionObserver(
            this.callbackHeaderIntersection,
            {
              root: null,
              threshold: [1.0],
            }
          );
          headerBGObserver.observe(projectIconElement);
        }

        this.observer.observe(projectIconElement);
      }

      public toggleSharing = () => {
        if (this.state.showDropDown) {
          this.setState({
            ...this.state,
            animateOut: true,
          });

          this.toggleSharingTimeout = setTimeout(() => {
            this.setState({
              ...this.state,
              animateOut: false,
              showDropDown: false,
            });
          }, 200);
        } else {
          this.setState({
            ...this.state,
            showDropDown: true,
          });
        }
      };

      public handleBuildSelection = (id: string) => {
        this.setState({ id });
      };

      public isSelected = (uuid, id, index) => {
        return (index === 0 && id === undefined) || uuid === id;
      };

      public handleModalButton = () => {
        this.setState({
          modalOpen: false,
          incompatibleModalOpen: false,
          loading: false,
        });
      };

      // TODO remove the check for the application download, we have to research further the apple protocol
      // TODO: it seems this functionality of code reapeted in other section better to merge it for the sake of DRY

      public handleBuildDownload = (
        e: Event,
        url: string,
        type,
        incompatibleForce
      ) => {
        e.stopPropagation();
        this.setState({ loading: true });

        const isAndroidDevice = getMobileOS() === "android";

        if (
          (type === "android" && !isAndroidDevice && !incompatibleForce) ||
          (type === "ios" && isAndroidDevice && !incompatibleForce)
        ) {
          // TODO: get rid of the dialog and using toast instade
          this.setState({ incompatibleModalOpen: true });
          return;
        }
        this.setState({ incompatibleModalOpen: false });

        if (iOSSafari() && !isRunningInStandaloneMode()) {
          // eslint-disable-next-line
          analytics.track("ApkDownload").then(() => {
            window.location.href = url;
            this.setState({ loading: false });
          });

          return;
        }

        const buildUuid = getUuifFromUrl(url);
        void fetchBuild(buildUuid).then((res) => {
          if (res.status !== 200) {
            this.setState({ modalOpen: true });
          } else {
            // eslint-disable-next-line
            analytics.track("ApkDownload").then(() => {
              window.location.href = url;
              this.setState({ loading: false });
            });
          }
        });
      };

      public render() {
        const { theme } = this.props.store.state;

        const menu = (build: Build) => (
          <div
            className={`second-layer project-details-menu
            ${this.state.animateOut ? "project-details-menu--out" : ""}`}
          >
            <div className="project-details-menu__sharing-head">
              <Text
                content="projectdetails.modal.header"
                color="text"
                spacing="-0,98px"
                size="xxlarge"
                bold="black"
                left
              />
              <IconButton
                icon={theme === "dark" ? "closeButtonWhite" : "closeButton"}
                size={"md"}
                noBorder
                onClick={this.toggleSharing}
              />
            </div>
            <Share
              theme={theme}
              id={build.uuid}
              toggleSharing={this.toggleSharing}
            />
          </div>
        );

        return (
          <Async uuid={this.props.id} promiseFn={getProject}>
            {({ data, error, isLoading }: Response) => {
              const { builds, name, icon, type } =
                data || ({} as ProjectDetailResponse);

              // TODO add all static data in a file
              const defaultIcon =
                window.origin + "/assets/icons/android-chrome-72x72.png";

              const build = builds
                ? builds.find((item) => item.uuid === this.state.id)
                : undefined;
              // If no build is selected, defaults to latest build.
              const selectedBuildIndex = build
                ? builds.findIndex((item) => item.uuid === build.uuid)
                : 0;
              const selectedBuild: Build = safeGet(builds, [
                `${selectedBuildIndex}`,
              ]) as Build;
              const historyBuilds: unknown[] = checkArray(builds || []);
              const primaryColor =
                theme === "dark"
                  ? "#222222"
                  : (safeGet(data, "primary_color") as string) ||
                    DEFAULT_HEADER_BG_COLOR;
              const textColor = getContrastColor(primaryColor, 125);
              const buttonColor =
                theme === "dark" ? theme : getContrastColor(primaryColor, 225);
              let content = null;
              let title = "";
              const isAndroidApk = type === "android";

              if (isLoading) {
                content = <Loading fullScreen />;
              }

              if (error) {
                switch (error.status) {
                  case 404:
                    route("/404");
                    break;

                  default:
                    route("/error");
                    break;
                }
              }

              if (data) {
                title = name;
                content = (
                  <div className={"first-layer project-details__content"}>
                    {selectedBuild && (
                      <div
                        className="second-layer text-center rounded-b-md p-3"
                        style={{ backgroundColor: primaryColor }}
                      >
                        <div>
                          <img
                            src={icon || defaultIcon}
                            alt={
                              icon
                                ? this.props.logoText + name
                                : this.props.defaultLogoText
                            }
                            class="project-details__icon"
                            onLoad={() => {
                              this.wireHeaderAnimation(primaryColor);
                            }}
                          />
                        </div>

                        <div class="mt-3 mb-3">
                          <Text
                            color={textColor}
                            opacity="medium"
                            bold="bold"
                            size="normal"
                            className="mb-2"
                          >
                            {name}
                          </Text>
                          <Text color={textColor} bold="black" size="large">
                            <Icon
                              name={isAndroidApk ? "android" : "apple"}
                              size={1}
                              className="mr-2"
                            />
                            {selectedBuild.version} (#
                            {selectedBuild.build_number})
                          </Text>
                        </div>
                        <div className={"mx-3"}>
                          <LatestBuild
                            {...selectedBuild}
                            short={false}
                            textColor={textColor}
                          />
                        </div>
                        {this.state.incompatibleModalOpen && (
                          <Dialog
                            title="warning.incompatible.title"
                            className="second-layer"
                            buttonText="button.download"
                            onConfirm={(e) =>
                              this.handleBuildDownload(
                                e,
                                selectedBuild.download_url,
                                type,
                                true
                              )
                            }
                            onClose={this.handleModalButton}
                            theme={theme}
                          >
                            <ModalMessage content="warning.incompatible.content" />
                          </Dialog>
                        )}
                        {selectedBuild.binary_exists ? (
                          <div
                            className={
                              "mx-3 display-inline-flex flex-row align-center justify-center"
                            }
                          >
                            <Button
                              backgroundColor={buttonColor}
                              className="mr-2"
                              size={"xl4"}
                              iconOnly={true}
                              iconLarge={true}
                              color={theme === "dark" ? "light" : primaryColor}
                              text={""}
                              icon={
                                theme === "dark"
                                  ? "link-small-white"
                                  : "link-small"
                              }
                              loading={this.state.loading}
                              disabled={this.state.loading}
                              noBorder={true}
                              bold={"bold"}
                              onClick={(event: Event) => {
                                const urlToCopy = projectURL(
                                  "build",
                                  this.props.id,
                                  selectedBuild.build_number
                                );
                                copyTextToClipboard(urlToCopy);
                                broadcast(
                                  this.props.copiedToClipboardMessage,
                                  ""
                                );
                                event.stopPropagation();
                              }}
                            />

                            <Button
                              onClick={(e) =>
                                this.handleBuildDownload(
                                  e,
                                  selectedBuild.download_url,
                                  type,
                                  false
                                )
                              }
                              disabled={this.state.loading}
                              loading={this.state.loading}
                              className="mt-4 mb-4"
                              color={theme === "dark" ? "light" : primaryColor}
                              backgroundColor={buttonColor}
                              noBorder
                              text={"button.download"}
                              bold={"bold"}
                            />
                          </div>
                        ) : (
                          <Paper elevation={2} className="mt-4 mx-1">
                            <div className="Paper__container my-4">
                              <Icon
                                name={"warning-small"}
                                size={3}
                                className="mr-6"
                              />
                              <Text
                                className="ml-4"
                                left
                                size="xsmall"
                                bold="semibold"
                                content="warning.unavailable_build"
                              />
                            </div>
                          </Paper>
                        )}
                      </div>
                    )}

                    {historyBuilds.length ? (
                      <div style={{ margin: "0 var(--project-item-gutter)" }}>
                        <Text
                          content="projectdetails.title"
                          element="h4"
                          className="mb-4 mt-6"
                          color={"gray"}
                          opacity={"medium"}
                        />
                        {historyBuilds.map((item: Build, index) => (
                          <div
                            key={item.uuid}
                            onClick={() => this.handleBuildSelection(item.uuid)}
                          >
                            <BuildItem
                              selected={
                                this.isSelected(
                                  item.uuid,
                                  this.state.id,
                                  index
                                ) || item.build_number === +this.props.hash
                              }
                              featured={item.build_number === +this.props.hash}
                              theme={theme}
                              handleClick={(e) => {
                                this.handleBuildSelection(item.uuid);
                                this.handleBuildDownload(
                                  e,
                                  item.download_url,
                                  type,
                                  false
                                );
                              }}
                              reference={
                                item.build_number === +this.props.hash
                                  ? this.autoScrollRef
                                  : null
                              }
                              handleLinkClick={(event) => {
                                const urlToCopy = projectURL(
                                  "build",
                                  this.props.id,
                                  item.build_number
                                );
                                copyTextToClipboard(urlToCopy);
                                broadcast(
                                  this.props.copiedToClipboardMessage,
                                  ""
                                );
                                event.stopPropagation();
                              }}
                              {...item}
                            />
                          </div>
                        ))}
                      </div>
                    ) : (
                      <MessageView
                        icon="noProjectsIllustration"
                        title="warning.no_build.title"
                        content="warning.no_build.content"
                      />
                    )}
                  </div>
                );
              }
              const isAndroidDevice = getMobileOS() === "android";
              return (
                <div
                  className="first-layer project-details"
                  ref={(projectDetailsElem) =>
                    (this.projectDetailsElem = projectDetailsElem)
                  }
                >
                  {this.state.modalOpen && (
                    <Dialog
                      theme={theme}
                      className="second-layer"
                      title="warning.modal.title"
                      onConfirm={() => this.handleModalButton()}
                      onClose={() => this.handleModalButton()}
                    >
                      <ModalMessage content="warning.modal.content" />
                    </Dialog>
                  )}
                  {this.state.showDropDown && (
                    <Modal onClose={this.toggleSharing}>
                      {menu(selectedBuild)}
                    </Modal>
                  )}
                  <Head title={`Projects / ${title}`} />
                  <Header
                    backgroundColor={
                      this.state.currentHeaderBackgroundColor || "transparent"
                    }
                    rightIcon={
                      selectedBuild &&
                      selectedBuild.binary_exists && (
                        <Fragment>
                          <IconButton
                            color={textColor}
                            className="mr-1"
                            icon={"link-small"}
                            size={"xl2"}
                            onClick={() => {
                              const urlToCopy = projectURL(
                                "project",
                                this.props.id,
                                selectedBuild.build_number
                              );
                              copyTextToClipboard(urlToCopy);
                              broadcast(
                                this.props.copiedToClipboardMessage,
                                ""
                              );
                            }}
                            noBorder
                          />
                          <IconButton
                            color={textColor}
                            icon={
                              isAndroidDevice ? "share-android" : "share-ios"
                            }
                            size={"xl"}
                            onClick={this.toggleSharing}
                            noBorder
                          />
                        </Fragment>
                      )
                    }
                    leftIcon={
                      <IconButton
                        color={textColor}
                        icon="arrow-left-long"
                        size={"xl"}
                        onClick={goBack}
                        noBorder
                      />
                    }
                  >
                    <div className="header__container__project">
                      <img
                        src={icon || defaultIcon}
                        alt={
                          icon
                            ? this.props.logoText + name
                            : this.props.defaultLogoText
                        }
                        className="header__container__project__icon"
                      />
                      <Text color={textColor}>{title}</Text>
                    </div>
                  </Header>
                  <Content firstLayer>{content}</Content>
                </div>
              );
            }}
          </Async>
        );
      }
    }
  )
);
