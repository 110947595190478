import { FunctionalComponent, h } from "preact";
import { useCallback, useEffect, useState } from "preact/hooks";
import { route } from "preact-router";

import Button, { IconButton } from "../../components/button";
import Content from "../../components/content";
import Form from "../../components/form";
import Head from "../../components/head";
import Header from "../../components/header";
import Input from "../../components/input";
import Text from "../../components/text";
import { goBack } from "../../utils/helpers";
import { changePassword } from "../../utils/http";
import { withStore } from "../../store";
import { ChangePasswordInputT, EventTargetType, InputsType } from "../../types";

import "./index.scss";

interface State {
  loading: boolean;
  notification: string;
  oldPasswordNotification: string;
  newPasswordNotifications: string[];
  isSubmitDisabled: boolean;
  inputs: ChangePasswordInputT;
}

const ChangePassword: FunctionalComponent = withStore(({ store }) => {
  const [state, setState] = useState<State>({
    loading: false,
    notification: null,
    oldPasswordNotification: null,
    newPasswordNotifications: null,
    isSubmitDisabled: true,
    inputs: {
      old_password: "",
      new_password1: "",
      new_password2: "",
    },
  });

  const isAllInputsFilled = useCallback((): void => {
    const { old_password, new_password1, new_password2 } = state.inputs;

    old_password !== "" &&
    new_password1 !== "" &&
    new_password2 !== "" &&
    new_password1 === new_password2
      ? setState({
          ...state,
          isSubmitDisabled: false,
        })
      : setState({
          ...state,
          isSubmitDisabled: true,
        });
    // eslint-disable-next-line
  }, [state.inputs]);

  const handleChangeInput = (event: EventTargetType): void => {
    const noPasswordMatch =
      event.target.name === "new_password2" &&
      state.inputs.new_password1 !== event.target.value;
    setState({
      ...state,
      oldPasswordNotification: event.target.name === "old_password" && null,
      inputs: {
        ...state.inputs,
        [event.target.name]: event.target.value,
      },
      notification: noPasswordMatch && "error.form.password",
    });
  };

  // eslint-disable-next-line
  useEffect(() => isAllInputsFilled(), [state.inputs]);

  const handleSubmitForm = (event: Event): void => {
    event.preventDefault();

    const { inputs } = state;
    setState({
      ...state,
      notification: null,
      loading: true,
    });

    changePassword(inputs)
      .then(() => {
        setState({
          ...state,
          loading: false,
        });
        route("/home");
      })
      .catch(({ message: error }: { message: { [key: string]: string[] } }) => {
        Object.keys(error).map((key) => {
          if (key === "old_password") {
            setState({
              ...state,
              oldPasswordNotification: error[key].toString(),
            });
          } else {
            setState({
              ...state,
              newPasswordNotifications: error[key],
            });
          }
        });
      });
  };

  const { theme } = store.state;
  const isDarkTheme = theme === "dark";

  const headerLeftIcon = (
    <IconButton
      icon="icon_arrow_back"
      color={isDarkTheme ? "light" : "dark"}
      size={"lg"}
      onClick={goBack}
      noBorder
    />
  );

  const headerRightIcon = (
    <Button
      text="button.done"
      className={isDarkTheme && "Button--white-border"}
      accent
      onClick={handleSubmitForm}
      white
      size="sm"
      disabled={state.isSubmitDisabled || state.loading}
    />
  );

  const inputsTop: InputsType = [
    {
      icon: ["eye", "eye-hide"],
      name: "old_password",
      type: "password",
      label: "placeholder.current_password",
      position: "top-bottom",
      required: true,
      validation: [],
    },
  ];

  const inputsBottom: InputsType = [
    {
      icon: ["eye", "eye-hide"],
      name: "new_password1",
      type: "password",
      label: "placeholder.new_password",
      position: "top",
      required: true,
      validation: [],
    },
    {
      icon: ["eye", "eye-hide"],
      name: "new_password2",
      type: "password",
      label: "placeholder.confirm_password",
      position: "bottom",
      validation: [],
    },
  ];

  const rules = {};
  const {
    notification,
    newPasswordNotifications,
    oldPasswordNotification,
  } = state;

  return (
    <Form
      className="first-layer change-password"
      rules={rules}
      onSubmit={handleSubmitForm}
    >
      {() => (
        <div>
          <Head title="Change Password" />
          <Header
            backgroundColor={isDarkTheme ? "#222222" : "#fefefe"}
            leftIcon={headerLeftIcon}
            rightIcon={headerRightIcon}
          >
            <Text content="changepassword.title" />
          </Header>
          <Content padded>
            <div className="first-layer form">
              <div className="input-container">
                {inputsTop.map((params) => (
                  <Input
                    key={params.name}
                    error={oldPasswordNotification}
                    {...params}
                    onInput={handleChangeInput}
                    // eslint-disable-next-line
                    value={state.inputs[params.name]}
                  />
                ))}
              </div>
              <Text
                content="link.forgot_credentials"
                color="secondary"
                bold="bold"
                left
                size="xsmall"
                className="mt-3 ml-4 mb-6"
              />

              {newPasswordNotifications &&
                newPasswordNotifications.map((error) => (
                  <Text
                    key={error}
                    content={error}
                    color="danger"
                    size="xsmall"
                    className="mb-1"
                  />
                ))}
              <div className="input-container">
                {inputsBottom.map((params) => (
                  <Input
                    key={params.name}
                    error={notification}
                    {...params}
                    onInput={handleChangeInput}
                    // eslint-disable-next-line
                    value={state.inputs[params.name]}
                  />
                ))}
              </div>
            </div>
          </Content>
        </div>
      )}
    </Form>
  );
});

export default ChangePassword;
