import { ProjectType } from "../types";
import { getMobileOS } from "./helpers";

export type ContrastColor = "light" | "dark";

const getContrastColor = (hex: string, baseContrast: number): ContrastColor => {
  const rgb = hexToRgb(hex);
  const contrast = Math.round(
    (Number(rgb[0]) * 299 + Number(rgb[1]) * 587 + Number(rgb[2]) * 114) / 1000
  );

  return contrast > baseContrast ? "dark" : "light";
};

const hexToRgb = (hex: string): number[] => {
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(
    shorthandRegex,
    (m: string, r: string, g: string, b: string) => {
      return r + r + g + g + b + b;
    }
  );

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ];
};

const downloadOrInstall = (buildType: ProjectType): string =>
  !getMobileOS() || buildType === "universal" ? "Download" : "Install";

const queryToObject = (
  query: string
): {
  [k: string]: string[] | string;
} => {
  if (query.substr(0, 1) === "?") {
    query = query.substr(1);
  }

  return query.split("&").reduce((prev, next) => {
    const [key, value] = next.split("=");
    if (key.substr(-2) === "[]") {
      const newKey = key.substring(0, key.length - 2);
      prev[newKey] = prev[newKey] ? [...prev[newKey], value] : [value];
    } else {
      prev[key] = value;
    }

    return prev;
  }, {} as { [k: string]: string[] | string });
};

const copyTextToClipboard = (text: string): void => {
  const virtualElement = document.createElement("textarea");
  virtualElement.value = text;
  document.body.appendChild(virtualElement);
  virtualElement.select();
  document.execCommand("copy");
  document.body.removeChild(virtualElement);
};

const projectURL = (
  type: string,
  uuid: string,
  build_number: number = null
): string => {
  switch (type) {
    case "build":
      return `${window.location.origin}/project/${uuid}/${build_number}`;

    default:
      return `${window.location.origin}/project/${uuid}`;
  }
};

export {
  downloadOrInstall,
  queryToObject,
  hexToRgb,
  getContrastColor,
  copyTextToClipboard,
  projectURL,
};
