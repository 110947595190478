import { FunctionalComponent, h } from "preact";
import { useTheme } from "../../utils/hooks";
import { useEffect, useState } from "preact/hooks";

import Head from "../../components/head";
import { withStore } from "../../store";
import analytics from "../../utils/analytics";
import { IconButton } from "../../components/button";
import SettingsScreen from "./SettingsScreen";
import TopBar from "../../components/topNav";
import ThemeScreen from "./ThemeScreen";

import "./index.scss";

type State = {
  analyticsSet: boolean;
  currentScreen: string;
};

const Settings: FunctionalComponent = withStore(({ store }) => {
  const [state, setState] = useState<State>({
    analyticsSet: false,
    currentScreen: "settings",
  });

  const [theme, toggleTheme] = useTheme();

  useEffect(() => {
    if (!state.analyticsSet) {
      // eslint-disable-next-line
      analytics.page();
      setState({
        ...state,
        analyticsSet: true,
      });
    }
  }, [state]);

  const handleThemeChange = (): void => {
    const oppositeTheme = theme === "dark" ? "light" : "dark";
    // eslint-disable-next-line
    analytics.track("themeChanged", {
      theme: oppositeTheme,
    });

    store.update({ theme: oppositeTheme });
    toggleTheme();
  };

  const handleScreenChange = (newScreen: string) =>
    setState({
      ...state,
      currentScreen: newScreen,
    });

  const isDarkTheme = theme === "dark";

  const leftIcon = (
    <IconButton
      icon="icon_arrow_back"
      size={"lg"}
      color={isDarkTheme ? "light" : "dark"}
      onClick={() => handleScreenChange("settings")}
      noBorder
    />
  );

  const { currentScreen } = state;

  return (
    <div className="first-layer settings">
      <Head title="Settings" />
      <TopBar settingsClick={() => handleScreenChange("settings")} />
      <div
        style={{
          margin: "0 auto",
          maxWidth: 600,
        }}
      >
        {currentScreen === "settings" && (
          <SettingsScreen onScreenChange={handleScreenChange} />
        )}
        {currentScreen === "theme" && (
          <ThemeScreen
            isToggled={theme === "dark"}
            handleThemeChange={handleThemeChange}
            leftIcon={leftIcon}
          />
        )}
      </div>
    </div>
  );
});

export default Settings;
