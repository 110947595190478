import { route } from "preact-router";
import fetch from "unfetch";
import { AsyncError } from "../components/async";
import { logout } from "./http";
import config from "../config";
import store from "./store";
import { errorCodes } from "./constants";

type Method = "GET" | "POST" | "DELETE";

export const errorCodeToString = (
  error: AsyncError,
  status: unknown
): AsyncError => {
  if (status) {
    switch (status) {
      case status === 404:
        return {
          message: error.code.toString(),
          status,
          string: "Unable to find the requested resource",
        };
      case status === 401:
        return {
          message: error.code.toString(),
          status,
          string: "Unauthorized",
        };
      case status === 403:
        return {
          message: error.code.toString(),
          status,
          string: "Forbidden",
        };
      default:
        return {
          message: error.code ? error.code.toString() : error,
          status,
        };
    }
  }
  return error;
};

type FetchErrorResponse = {
  message: { code: string } | string;
  status: unknown;
  code: unknown;
  detail: unknown;
};

function apiFetch<T>(
  url: string,
  method: Method = "GET",
  data: Record<string, unknown> = {}
): Promise<T> {
  const options: RequestInit = {
    method,
    headers: {
      Accept: `application/json; version=${config.API_VERSION}`,
      "Content-Type": "application/json",
    },
  };

  if (data) {
    options.body = JSON.stringify(data);
  }

  const token: string = store.get("token");
  if (token) {
    options.headers["Authorization"] = `Token ${token}`;
  }

  // eslint-disable-next-line
  // @ts-ignore
  return fetch(url, options)
    .then((response) => {
      const contentType = response.headers.get("content-type") || false;
      const type =
        contentType && contentType.indexOf("json") >= 0 ? "json" : "text";

      if (!response.ok) {
        return response[type]().then((err) =>
          Promise.reject(errorCodeToString(err, response.status))
        );
      }

      return response[type]() as Promise<T>;
    })
    .catch((error: FetchErrorResponse) => {
      const { message, status, code, detail } = error;

      if (error instanceof ProgressEvent || error instanceof TypeError) {
        route("/unreachable");
      }

      if (!window.navigator.onLine) {
        route("/offline");
      }

      if (error && code >= 500) {
        route("/error");
      }

      if (
        (message === errorCodes.userNotFound && status === 401) ||
        detail === errorCodes.userNotFound ||
        message === errorCodes.invalidToken
      ) {
        logout();
        route("/login");
      }

      throw error;
    });
}

// ajax get method
export function get<T>(path: string): Promise<T> {
  return new Promise<T>((resolve, reject) => {
    apiFetch<T>(config.API_URL + path, "GET", null)
      .then((res) => {
        resolve(res);
      })
      .catch((error: FetchErrorResponse) => {
        const { message, status, detail } = error;

        if (
          (message === errorCodes.userNotFound && status === 401) ||
          message === errorCodes.invalidToken ||
          detail === errorCodes.credentialsNotProvided
        ) {
          logout();
          route("/login");
        }
        reject(error);
      });
  });
}

// ajax post method
export function post<T>(
  path: string,
  data: Record<string, unknown>
): Promise<T> {
  return apiFetch<T>(config.API_URL + path, "POST", data);
}

const cacheStore = new Map();

export const clearCache = (): void => {
  cacheStore.clear();
};

// uses cache first strategy
// return the data from the cache and refresh the cache after that for the next request.
// basicly is the cache data is always 1 request behind
export function getCacheFirst<T>(path: string): Promise<T> {
  // the http request
  const resolve = get(path).then((data: T) => {
    cacheStore.set(path, data);
    return Promise.resolve(data);
  });

  // return the cache firts
  if (cacheStore.has(path)) {
    return Promise.resolve(cacheStore.get(path) as T);
  }
  return resolve;
}
