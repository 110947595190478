import { h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { withStore } from "../../store";
import { downloadBuild } from "../../utils/http";

import "./index.scss";
import { route } from "preact-router";
import Loading from "../../components/loading";
import Text from "../../components/text";
import Button from "../../components/button";

interface State {
  loading: boolean;
  error: boolean;
}

const DownloadBuild = withStore(({ store }) => {
  const [state, setState] = useState<State>({
    loading: false,
    error: false,
  });

  useEffect(() => {
    if (!state.loading && !state.error) {
      setState({
        ...state,
        loading: true,
      });
      const uuid = window.location.pathname
        .split("/builds/")[1]
        .split("/download")[0];

      downloadBuild({ uuid })
        .then((res) => {
          if (res.status !== "success") {
            setState({
              loading: false,
              error: true,
            });
            return;
          }
          window.location.href = res.url;
          setTimeout(() => {
            setState({
              ...state,
              loading: false,
              error: false,
            });
            route("/home");
          }, 2000);
        })
        .catch(() =>
          setState({
            loading: false,
            error: true,
          })
        );
    }
  }, [state]);

  const { theme } = store.state;
  const { loading, error } = state;
  return (
    <div
      className="first-layer download-build
    flex justify-center"
    >
      {loading && <Loading />}
      {!loading && error && (
        <div className={"error w-1/2"}>
          <img
            className={"mb-5 w-1/2 h-auto text-center"}
            src="../../components/icon/svg/404Error.svg"
            alt="Page not found."
          />
          <Text
            content="downloadbuild.not_found"
            center
            lineHeight={"28px"}
            color="gray"
            size="large"
          />
          <div className={"button_wrapper"}>
            <Button
              text={"downloadbuild.go_home"}
              gradient
              bottom_float
              backgroundColor={theme === "dark" && theme}
              onClick={() => route("/home")}
            />
          </div>
        </div>
      )}
    </div>
  );
});

export default DownloadBuild;
