import { h, FunctionalComponent } from "preact";
import Text from "../../components/text";
import Icon from "../../components/icon";

interface Props {
  onScreenChange: (newScreen: string) => void;
}

const SettingsScreen: FunctionalComponent<Props> = ({ onScreenChange }) => {
  return (
    <div
      style={{
        padding: "50px var(--project-item-gutter)",
      }}
    >
      <Text
        content="settings.title"
        left
        bold="bold"
        color="light-gray"
        size="slarge"
        className="mt-6 mb-4"
      />
      <div
        className="second-layer wide-button"
        onClick={() => onScreenChange("theme")}
      >
        <Icon className="mr-1" name="theme" size={3} />
        <Text
          left
          bold="bold"
          color={"dark"}
          opacity="high"
          content="settings.theme.title"
        />
      </div>
    </div>
  );
};

export default SettingsScreen;
