import { FunctionalComponent, h } from "preact";
import Async, { AsyncCallbackParams } from "../../components/async";
import Button from "../../components/button";
import Content from "../../components/content";
import Loading from "../../components/loading";
import Text from "../../components/text";
import { ShareItem, ThemeType } from "../../types";
import { ShareResponse } from "../../types/responses";
import { getBuildShares, saveShare } from "../../utils/http";
import ShareSelected from "./components/shareselected";
import "./index.scss";
import { useState } from "preact/hooks";
import { compareArrays } from "../../utils/helpers";

type Response = AsyncCallbackParams<ShareResponse>;

interface Props {
  id: string;
  theme?: ThemeType;
  toggleSharing?: () => void;
}

interface State {
  isSubmitDisabled: boolean;
  selectedUsers: string[];
  loadedData: boolean;
  snapshotUsers: string[];
}

const Share: FunctionalComponent<Props> = ({ toggleSharing, id, theme }) => {
  const [state, setState] = useState<State>({
    isSubmitDisabled: true,
    selectedUsers: [],
    snapshotUsers: [],
    loadedData: false,
  });

  const handleSubmitForm = (
    event: Event,
    action: (params?: unknown) => void
  ) => {
    event.preventDefault();
    const data = { groups: state.selectedUsers };
    // eslint-disable-next-line
    saveShare(id, "build", data).then(() => {
      action();
      setState({
        ...state,
        selectedUsers: [],
        isSubmitDisabled: true,
      });
      toggleSharing();
    });
  };

  const handleSelect = (item: ShareItem): void => {
    const { selectedUsers, snapshotUsers } = state;
    let newUsers: string[];

    if (selectedUsers.indexOf(item.uuid) >= 0) {
      newUsers = selectedUsers.filter((uuid) => uuid !== item.uuid);
      setState({
        ...state,
        selectedUsers: newUsers,
        isSubmitDisabled:
          !selectedUsers.length || compareArrays(snapshotUsers, newUsers),
      });
    } else {
      newUsers = [...selectedUsers, item.uuid];
      setState({
        ...state,
        selectedUsers: newUsers,
        isSubmitDisabled:
          !selectedUsers.length || compareArrays(snapshotUsers, newUsers),
      });
    }
  };

  return (
    <Async uuid={id} promiseFn={getBuildShares}>
      {({ data, error, isLoading, reload }: Response) => {
        let content = null;
        let items = [];
        if (isLoading) {
          content = <Loading />;
        } else if (error) {
          content = <p>{error.toString()}</p>;
        } else if (data) {
          // tslint:disable-next-line:no-unused-expression
          !state.loadedData &&
            data.assigned &&
            setState({
              ...state,
              selectedUsers: data.assigned.map((user) => user.uuid),
              snapshotUsers: data.assigned.map((user) => user.uuid),
              loadedData: true,
            });
          items =
            data.available || data.assigned
              ? [...data.available, ...data.assigned]
              : [];
          content = (
            <div>
              <Text
                className={"mt-4"}
                content={"projectdetails.modal.remove.header"}
                color={"light-gray"}
                bold={"semibold"}
              />
              {items && items.length ? (
                <div className="share-container__bottom-divider">
                  <ShareSelected
                    items={items}
                    selected={state.selectedUsers}
                    onSelect={(item) => handleSelect(item)}
                  />
                </div>
              ) : (
                <Text
                  content="projectdetails.modal.add.content"
                  className={"mt-4"}
                  color={"dark"}
                  bold={"semibold"}
                />
              )}
            </div>
          );
        }

        return (
          <div className="share-container">
            <div className="share-container__main">
              <Content noHeader>{content}</Content>
            </div>
            <div className="share-container__footer">
              <Button
                text="button.confirm"
                gradient
                backgroundColor={theme === "dark" && theme}
                bottom_float
                disabled={isLoading || state.isSubmitDisabled}
                onClick={(event) => handleSubmitForm(event, reload)}
              />
            </div>
          </div>
        );
      }}
    </Async>
  );
};

export default Share;
