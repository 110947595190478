import classnames from "classnames";
import { Component, h, JSX } from "preact";
import { MarkupText } from "preact-i18n";
import { CSSProperties } from "../button";

import "./index.scss";

const other: string[] = [
  "tag",
  "italic",
  "center",
  "left",
  "right",
  "single",
  "upper",
];

const removeUnusedProps = (props: Props): Props => {
  const newProps: Props = {};
  const classess: string[] = other;
  Object.keys(props).forEach((key) => {
    if (classess.indexOf(key) === -1) {
      // eslint-disable-next-line
      newProps[key] = props[key];
    }
  });

  return newProps;
};

const addClass = (className = "", options: string[], props: Props): string => {
  options.forEach((option) => {
    if (props[option]) {
      className += ` text-${option}`;
    }
  });
  return className;
};

export type TextColors =
  | "text"
  | "light"
  | "primary"
  | "secondary"
  | "gray"
  | "dark"
  | "dark-gray"
  | "danger"
  | "light-gray"
  | "light-green"
  | string;

export type FontWeight =
  | "black"
  | "heavy"
  | "bold"
  | "semibold"
  | "medium"
  | "regular"
  | "thin"
  | "light"
  | "ultralight";

interface Props {
  // eslint-disable-next-line
  children?: any;
  size?:
    | "xxsmall"
    | "xsmall"
    | "small"
    | "normal"
    | "slarge"
    | "large"
    | "xlarge"
    | "xxlarge"
    | "title"
    | "xtitle"
    | "sheading"
    | "heading"
    | "xheading"
    | "body"
    | "danger";
  color?: TextColors;
  bold?: FontWeight;
  opacity?:
    | "transparent"
    | "xlow"
    | "low"
    | "medium"
    | "high"
    | "xhigh"
    | "opaque";
  tag?: boolean;
  italic?: boolean;
  center?: boolean;
  style?: CSSProperties;
  single?: boolean;
  upper?: boolean;
  lineHeight?: string;
  light?: boolean;
  element?: string;
  className?: string;
  spacing?: string;
  onClick?: () => void;
  wrap?: boolean;
  right?: boolean;
  left?: boolean;
  content?: string;
}

class Text extends Component<Props> {
  // TODO Remove this component types
  public static Title: (args?: Props) => JSX.Element;
  public static Small: (args?: Props) => JSX.Element;
  public static Section: (args?: Props) => JSX.Element;
  public static InHeader: (args?: Props) => JSX.Element;

  public render(): JSX.Element {
    const {
      element = "p",
      // eslint-disable-next-line
      children,
      size,
      color,
      opacity = "opaque",
      bold,
      wrap,
      className = "",
      content,
      ...rest
    } = this.props;

    const newClassName = classnames(
      [other].reduce((prev, current) => addClass(prev, current, rest), ""),
      className,
      "text-base",
      {
        [`text-${size}`]: size,
        [`text-${color}`]: color,
        [`opacity-${opacity}`]: opacity,
        [`bold-${bold}`]: bold,
        [`text-wrap`]: wrap,
      }
    );

    const newProps = removeUnusedProps(rest);

    // eslint-disable-next-line
    const Tag = element as any;
    return (
      <Tag
        {...newProps}
        className={newClassName}
        style={{
          lineHeight: this.props.lineHeight,
          letterSpacing: this.props.spacing,
          textOverflow: "ellipsis",
          width: "100%",
          overflow: "hidden",
        }}
      >
        {/* this ensures that text with no id will display the encapsulated text */}
        <MarkupText id={content ? content : ""}>
          {" "}
          {content ? content : children}{" "}
        </MarkupText>
      </Tag>
    );
  }
}

export const Title = (props: Props): JSX.Element => (
  <Text color="primary" size="large" {...props} />
);
Text.Title = Title;

export const Small = (props: Props): JSX.Element => (
  <Text size="small" {...props} />
);
Text.Small = Small;

export const Section = (props: Props): JSX.Element => (
  <Text upper color="dark" {...props} />
);
Text.Section = Section;

export const InHeader = (props: Props): JSX.Element => (
  <Text bold={"bold"} center light {...props} />
);
Text.InHeader = InHeader;

export default Text;
