import { FunctionalComponent, h } from "preact";
import { useState } from "preact/hooks";
import { ProjectSortOrder, ProjectType, ThemeType } from "../../types";
import analytics from "../../utils/analytics";
import {
  Filter,
  FILTER_ITEMS,
  FilterItem,
  SORTBY_ITEMS,
} from "../../utils/constants";
import Button, { IconButton } from "../button";
import Checkbox from "../checkbox";
import Icon from "../icon";
import Modal from "../modal";
import Text from "../text";
import { compareArrays } from "../../utils/helpers";

interface Props {
  show: boolean;
  theme: ThemeType;
  onConfirm: (
    a: ProjectType[] | ProjectSortOrder[],
    b: ProjectType[] | ProjectSortOrder[]
  ) => void;
  selectedPlatforms: ProjectType[] | null;
  selectedSortBy: ProjectSortOrder[];
}

type SelectedFilter = "platforms" | "sorting" | "none";

const checkGroup = (key: string, selected: string[]) => {
  return (
    <div class="home-menu__group">
      <div class="home-menu__group home-menu__check">
        <Checkbox checked={selected && selected.includes(key)} />
      </div>
      <Text content={`home.modal.filter.${key}`} />
    </div>
  );
};

type State = {
  localSelectedPlatforms: ProjectType[];
  localSelectedSortBy: ProjectSortOrder[];
  animateOut: boolean;
};

const BuildPlatformFilterMenu: FunctionalComponent<Props> = ({
  show,
  theme,
  onConfirm,
  selectedPlatforms,
  selectedSortBy,
}) => {
  const [expandedSection, setExpandedSection] = useState<SelectedFilter>(
    "none"
  );
  const [state, setState] = useState<State>({
    localSelectedPlatforms: selectedPlatforms,
    localSelectedSortBy: selectedSortBy,
    animateOut: false,
  });

  const changePlatform = (platform: ProjectType) => {
    // eslint-disable-next-line
    analytics.track("changePlatform", {
      newPlatform: platform,
    });

    if (state.localSelectedPlatforms.indexOf(platform) !== -1) {
      if (
        state.localSelectedPlatforms.length === 1 &&
        state.localSelectedPlatforms[0] === platform
      ) {
        return;
      }
      setState({
        ...state,
        localSelectedPlatforms: state.localSelectedPlatforms.filter(
          (item) => item !== platform
        ),
      });
    } else {
      setState({
        ...state,
        localSelectedPlatforms: [...state.localSelectedPlatforms, platform],
      });
    }
  };

  const changeSortOrder = (sortBy: ProjectSortOrder) => {
    setState({
      ...state,
      localSelectedSortBy: [sortBy],
    });
  };

  const filters: FilterItem[] = [
    {
      title: "home.modal.headers.platform",
      type: "platforms",
      items: FILTER_ITEMS,
      onChange: changePlatform,
      selected: [...state.localSelectedPlatforms],
    },
    {
      title: "home.modal.headers.sort_by",
      type: "sorting",
      items: SORTBY_ITEMS,
      onChange: changeSortOrder,
      selected: [...state.localSelectedSortBy],
    },
  ];

  const constructKey = (items: Filter, selected: string[]) => {
    const path = selected.length !== 0 ? "home.modal.filter" : "None";
    return items
      .filter((item) => selected && selected.includes(item.key))
      .reduce(
        (acc, item) =>
          acc === path ? `${acc}.${item.key}` : `${acc}-${item.key}`,
        path
      );
  };

  const filterGroups = filters.map(
    ({ items, title, type, selected, onChange }, index) => (
      <div key={index} className="home-menu__filter-head mt-6 mb-6">
        <div>
          <Text
            content={title}
            className="mt-2"
            color="light-gray"
            size="xsmall"
            bold="semibold"
            left
          />
          <div>
            {expandedSection !== type ? (
              <div>
                <div
                  className="home-menu__filter-head"
                  onClick={() => toggle(type)}
                >
                  <Text
                    content={constructKey(items, selected)}
                    className="mt-4"
                    color="dark"
                    size="slarge"
                    bold="thin"
                    left
                  />
                  <Icon className={"pl-2 pt-2"} name="arrow-right" size={4} />
                </div>
                {index === 0 && <div className="home-menu__border" />}
              </div>
            ) : (
              items.map(({ key }, filterIndex) => (
                <div key={key}>
                  <div className="home-menu__filter-head">
                    <Text
                      className="mt-2"
                      color="dark"
                      size="slarge"
                      bold="thin"
                      left
                      onClick={() => onChange(key)}
                    >
                      {checkGroup(key, selected)}
                    </Text>
                    {filterIndex === 0 && (
                      <Icon
                        name="arrow-down"
                        size={4}
                        onClick={() => toggle(type, true)}
                      />
                    )}
                  </div>
                  <div
                    className={
                      "home-menu__border " + (filterIndex === 0 && "ml-6")
                    }
                  />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    )
  );

  const toggle = (type, forceClose = false) => {
    if (type === expandedSection && forceClose) {
      return setExpandedSection("none");
    } else {
      setExpandedSection(type);
    }
  };

  const handleOnConfirm = () => {
    setState({
      ...state,
      animateOut: true,
    });
    setExpandedSection("none");
    setTimeout(() => {
      onConfirm(state.localSelectedPlatforms, state.localSelectedSortBy);
      setState({
        ...state,
        animateOut: false,
      });
    }, 300);
  };

  const isConfirmDisabled =
    compareArrays(state.localSelectedSortBy, selectedSortBy) &&
    compareArrays(state.localSelectedPlatforms, selectedPlatforms);

  const reset = () => {
    setState({
      animateOut: true,
      localSelectedPlatforms: selectedPlatforms,
      localSelectedSortBy: selectedSortBy,
    });
    setExpandedSection("none");
    setTimeout(() => {
      onConfirm(selectedPlatforms, selectedSortBy);
      setState({
        ...state,
        animateOut: false,
      });
    }, 200);
  };

  return (
    show && (
      <Modal onClose={reset}>
        <div
          className={`second-layer home-menu ${
            state.animateOut ? "home-menu--out" : ""
          }`}
        >
          <div className="home-menu__filter-head">
            <Text
              content="home.modal.title"
              color="dark"
              size="xxlarge"
              bold="black"
              left
            />
            <IconButton
              icon={theme === "dark" ? "closeButtonWhite" : "closeButton"}
              size={"xs"}
              noBorder
              onClick={reset}
            />
          </div>
          {filterGroups}
          <div className="share-container__footer">
            <Button
              text="button.confirm"
              gradient
              backgroundColor={theme === "dark" && theme}
              bottom_float
              disabled={isConfirmDisabled}
              onClick={handleOnConfirm}
            />
          </div>
        </div>
      </Modal>
    )
  );
};

export default BuildPlatformFilterMenu;
