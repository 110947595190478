import { FunctionalComponent, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { useTheme } from "../../utils/hooks";
import TopNavButton from "./TopNavButton";

import "./index.scss";

interface Props {
  settingsClick?: () => void;
}

const homePath = "/home";
const profilePath = "/profile";
const settingsPath = "/settings";

const TopBar: FunctionalComponent<Props> = ({ settingsClick }) => {
  const [selectedRoute, setSelectedRoute] = useState<string>(null);
  const [theme] = useTheme();

  useEffect(() => {
    const path = window.location.pathname;
    setSelectedRoute(path);
  }, []);

  const isHomeScreen = selectedRoute === homePath;
  const isProfileScreen = selectedRoute === profilePath;
  const isSettingsScreen = selectedRoute === settingsPath;

  const isDarkTheme = theme === "dark";

  return (
    <nav
      className="topNav second-layer"
      style={{
        boxShadow: isDarkTheme ? "" : "2px 0 20px #dcdcdc",
      }}
    >
      <TopNavButton
        icon={isHomeScreen ? "projects-active" : "projects"}
        label={"Apps"}
        path={homePath}
        selected={isHomeScreen}
      />
      <TopNavButton
        icon={isProfileScreen ? "person-active" : "person"}
        label={"Profile"}
        path={profilePath}
        selected={isProfileScreen}
      />
      <TopNavButton
        icon={isSettingsScreen ? "settings-active" : "settings"}
        label={"Settings"}
        path={settingsPath}
        onClick={settingsClick}
        selected={isSettingsScreen}
      />
    </nav>
  );
};

export default TopBar;
